import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import Notifications from 'vue-notification'
import Donut from 'vue-css-donut-chart'
import VueSmoothScroll from 'vue2-smooth-scroll'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Cookies from 'js-cookie'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
  loaderOptions: {
    autoHideBadge: true
  }
})

Vue.use(Donut)
Vue.use(VueSmoothScroll)

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
    bootstrap: Cookies.get('tracking_confirmed') ? true : false
  },
  router
)

// 1. For dynamic components - now it is not nessecary to do it manually
const requireComponent = require.context(
  './components', // the relative path of the directory to search
  false, // subdirectories will not be searched
  /Base[A-Z]\w+\.(vue|js)$/ // regular expression that searches for components starting with "Base" and ending in .vue or .js
)
// 2. For dynamic components - now it is not nessecary to do it manually
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')) // removes what's before and after the filename itself
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Notifications)

Vue.config.productionTip = false

// meta tags configuration
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

/*
axios.interceptors.response.use(null, error => {
  switch (error.response.status) {
    case 401:
      router.push('/auth/login')
      break
    case 404:
      router.push('/404')
      break
  }
  return Promise.reject(error)
})
*/

function displayErrors(err) {
  if (Array.isArray(err)) {
    err.forEach(error => {
      Vue.notify({
        type: 'warn',
        group: 'main',
        title: 'Fehler',
        text: error.detail,
        duration: 7000
      })
    })
  } else {
    Vue.notify({
      type: 'warn',
      group: 'main',
      title: 'Fehler',
      text: err.detail,
      duration: 7000
    })
  }
}

// eslint-disable-next-line no-unused-vars
Vue.config.errorHandler = function(err, vm, info) {
  //console.log('[ERROR CATCH]: ', err)
  //console.log('[ERROR COMPONENT]: ', vm)
  //console.log('[ERROR INFO]: ', info)
  displayErrors(err)
}

// Automatic auth - get basic auth for requests
/*
Vue.prototype.$api = new LunityApi({
  //basePath: 'http://192.168.0.241:8002/api/',
  apiKey: 'XYZ',
  csrfToken: Cookies.get('csrftoken')
})
*/

Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

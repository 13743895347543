import Vue from 'vue'
import VueRouter from 'vue-router'

const NavigationSearch = () =>
  import(
    /* webpackChunkName: "NavigationSearch" */ '../views/level0/Navigation.vue'
  )
const DefaultTemplate = () =>
  import(
    /* webpackChunkName: "DefaultTemplate" */ '../templates/level0/DefaultTemplate.vue'
  )

const Intro = () =>
  import(/* webpackChunkName: "ExpectationSteps" */ '../views/level0/Intro.vue')

const Customers = () =>
  import(
    /* webpackChunkName: "ExpectationSteps" */ '../views/level0/Customers.vue'
  )

const Contact = () =>
  import(
    /* webpackChunkName: "ExpectationSteps" */ '../views/level0/Contact.vue'
  )

const Agent = () =>
  import(/* webpackChunkName: "ExpectationSteps" */ '../views/level0/Agent.vue')

const Procedure = () =>
  import(
    /* webpackChunkName: "ExpectationSteps" */ '../views/level0/Procedure.vue'
  )

const Welcome = () =>
  import(/* webpackChunkName: "Next" */ '../templates/level1/Welcome')

const DataProtection = () =>
  import(/* webpackChunkName: "Next" */ '../views/level0/DataProtection')

const Imprint = () =>
  import(/* webpackChunkName: "Next" */ '../views/level0/Imprint')

//import NotFound from '../views/exceptions/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    components: {
      navigation: NavigationSearch,
      template: DefaultTemplate
    },
    props: {
      navigation: {
        focusOnSearch: true,
        backgroundDark: false
      },
      template: {
        metaInfo: {
          title: 'ProfiAssekuranz Wiesbaden | Versicherungsmakler',
          meta: [
            {
              vmid: 'description',
              name: 'description',
              content:
                'Versicherungsmakler in Wiesbaden seit 1983. Die Leistungen sind für Sie kostenfrei; unsere Courtage ist in die Versicherungsbeiträge bereits einkalkuliert.'
            }
          ]
        }
      }
    },
    children: [
      {
        path: '',
        name: 'intro',
        components: { center: Welcome },
        props: {
          top: {
            headline: 'Vielfälltig',
            text:
              'Versicherungen sind so vielfältig wie die Menschen und ihre Bedürfnisse selbst!',
            //image: require('@/assets/app/city.png'),
            transparent: true,
            imageStyle: {
              width: '50%',
              right: '9%'
            }
            /*
            button: {
              text: 'Mobile App',
              link: '/mobile/'
            }
            */
          },
          center: {},
          next: {
            names: ['mobile', 'management']
          }
        },
        children: [
          {
            path: '',
            components: {
              intro: Intro,
              agent: Agent,
              customers: Customers,
              procedure: Procedure,
              contact: Contact
            },
            props: {
              intro: {
                steps: [
                  { number: 14, header: 'Krankheit', text: '' },
                  { number: 1, header: 'Haftpflicht', text: '' },
                  { number: 2, header: 'Altervorsoge', text: '' },
                  { number: 3, header: 'Hausrat', text: '' },
                  { number: 4, header: 'Berufsunfähigkeit', text: '' },
                  { number: 5, header: 'KFZ', text: '' },
                  { number: 6, header: 'Reise', text: '' },
                  { number: 7, header: 'Unfall', text: '' },
                  { number: 8, header: 'Tiere', text: '' },
                  { number: 9, header: 'Rechtsschutz', text: '' },
                  { number: 10, header: 'Glas', text: '' },
                  { number: 11, header: 'Elementar', text: '' },
                  { number: 12, header: 'D&O', text: '' },
                  { number: 13, header: 'Gebäude', text: '' },
                  { number: 15, header: 'Dread Disease', text: '' }
                ]
              },
              agent: {},
              customers: {},
              procedure: {},
              contact: {}
            }
          }
        ]
      }
    ],
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/rechtlich',
    components: {
      navigation: NavigationSearch,
      template: DefaultTemplate
    },
    props: {
      template: {
        metaInfo: {
          title: 'Profiassekuranz | Rechtlich',
          meta: [
            {
              vmid: 'description',
              name: 'description',
              content: 'Rechtliche Hinweise'
            }
          ]
        }
      }
    },
    children: [
      {
        path: 'datenschutz',
        name: 'datenschutz',
        components: { center: DataProtection }
      },
      {
        path: 'impressum',
        name: 'impressum',
        components: { center: Imprint }
      }
    ],
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/*',
    // eslint-disable-next-line no-unused-vars
    redirect: to => {
      return '/'
    },
    meta: {
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user')) {
      next()
      return
    }
    next('/auth/login')
  } else {
    next()
  }
})

export default router

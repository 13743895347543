var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":""},on:{"hide":_vm.hideTrackingModal},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h5',[_vm._v("Cookies")])]}},{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('p',[_vm._v(" Hier werden Cookies verwendet. Die Datenschutzbestimmung kann "),_c('b-link',{staticClass:"font-weight-bold",attrs:{"href":"/rechtlich/datenschutz"}},[_vm._v(" hier ")]),_vm._v(" gelesen werden. ")],1),_c('b-button',{attrs:{"size":"lg","variant":"primary"},on:{"click":_vm.confirmTracking}},[_vm._v(" Ok ")])]}},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
var hide = ref.hide;
return [_c('b-link',{attrs:{"href":"/rechtlich/datenschutz"}},[_vm._v("Weitere Informationen")]),_c('b-button',{attrs:{"size":"lg","variant":"primary"},on:{"click":_vm.confirmTracking}},[_vm._v(" Ok ")])]}}]),model:{value:(_vm.trackingModalShow),callback:function ($$v) {_vm.trackingModalShow=$$v},expression:"trackingModalShow"}})}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'

const mailStoreModule = {
  namespaced: true,
  state: {
    statusCSRF: '',
    statusContactEmail: ''
  },
  mutations: {
    SET_STATUS_CONTACT_EMAIL_REQUEST_OK(state) {
      state.statusContactEmail = 'ok'
    },
    SET_STATUS_CSRF_REQUEST_OK(state) {
      state.statusCSRF = 'ok'
    },
    SET_STATUS_CONTACT_EMAIL_REQUEST_LOADING(state) {
      state.statusContactEmail = 'loading'
    },
    SET_STATUS_CSRF_REQUEST_LOADING(state) {
      state.statusCSRF = 'loading'
    },
    SET_STATUS_CONTACT_EMAIL_REQUEST_ERROR(state) {
      state.statusContactEmail = 'error'
    },
    SET_STATUS_CSRF_REQUEST_ERROR(state) {
      state.statusCSRF = 'error'
    }
  },
  actions: {
    sendContactEmail({ commit }, { email, token }) {
      commit('SET_STATUS_CONTACT_EMAIL_REQUEST_LOADING')
      return Vue.prototype.$axios
        .post(
          process.env.VUE_APP_BASE_API_URL + '/contact/send-contact-mail/',
          {
            type: 'User',
            attributes: {
              email: email,
              token: token
            }
          }
        )
        .then(() => {
          setTimeout(function() {
            commit('SET_STATUS_CONTACT_EMAIL_REQUEST_OK')
          }, 1000)
        })
        .catch(error => {
          setTimeout(function() {
            commit('SET_STATUS_CONTACT_EMAIL_REQUEST_ERROR')
          }, 1000)
          throw error // catched in main.js
        })
    },
    fetchCSRF({ commit }) {
      commit('SET_STATUS_CSRF_REQUEST_LOADING')
      return Vue.prototype.$axios
        .get(process.env.VUE_APP_BASE_API_URL + '/contact/csrf/')
        .then(() => {
          setTimeout(function() {
            commit('SET_STATUS_CSRF_REQUEST_OK')
          }, 1000)
        })
        .catch(error => {
          setTimeout(function() {
            commit('SET_STATUS_CSRF_REQUEST_ERROR')
          }, 1000)
          throw error // catched in main.js
        })
    }
  },
  getters: {}
}

export default { mailStoreModule }

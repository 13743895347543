<template>
  <div id="app">
    <router-view name="navigation" :key="$route.fullPath" />

    <div id="page-content-wrapper">
      <router-view name="template" :key="$route.fullPath" />
    </div>

    <TrackingModal />
    <notifications classes="notification" group="main" position="top right" />
  </div>
</template>

<script>
import axios from 'axios'
import TrackingModal from './components/TrackingModal'
import AOS from 'aos'

export default {
  components: { TrackingModal },
  created: function() {
    axios.interceptors.response.use(undefined, function(err) {
      // eslint-disable-next-line no-unused-vars
      return new Promise(function(resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          // if you ever get an unauthorized, logout the user
          this.$store.dispatch('articleStore/logoutUser')
          this.$router.push('/auth/login')
        }
        throw err
      })
    })
  },
  mounted() {
    AOS.init()
    // load user from localstorage
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'))
      this.UserModel.create({ data: user })
    }
  },
  methods: {},
  computed: {
    UserModel() {
      return this.$store.$db().model('User')
    }
  }
}
</script>

<style lang="sass">
// Theme
@import 'assets/sass/theme/theme.sass'

// libs
@import 'aos/dist/aos'
@import 'vue-css-donut-chart/dist/vcdonut'

// COLORS AREAS


// SIZES: FONTS
$font-sizes: ("1": 1rem, "2": 1.6rem, "3": 1.8rem, "4": 2.1rem, "5": 2.4rem, "6": 2.8rem, "7": 3.2rem, "8": 3.6rem, "9": 4.0rem, "10": 5.0rem)

#app
  min-height: 100vh
  background-color: $color-background
  overflow: hidden
  //hyphens: auto
  max-width: 1500vmax
  color: map-get($grays, "500")


  @media (prefers-color-scheme: light)
    background-color: $color-background-light
    color: map-get($grays, "700")
    font-weight: 400

@include media-breakpoint-between(xs, sm)
  .container-fluid
    padding: 0


#page-content-wrapper
  min-width: 100vw


.mb-space
  margin-bottom: 60px

.notification
  @include px(4)
  @include py(4)
  @include mt(3)
  @include mr(3)
  border-radius: $border-radius

  .notification-title
    // Style for title line


  .notification-content
    // Style for content


  &.my-type
    //Style for specific type of notification, will be applied when you
    //call notification with "type" parameter:
    //this.$notify({ type: 'my-type', message: 'Foo' })

  &.warn
    background: #ffb648
    border-left-color: #f48a06

  &.error
    background: #E54D42
    border-left-color: #B82E24


  &.success
    background: #68CD86
    border-left-color: #42A85F

.headline-small-uppercase
  @extend .fs-3
  display: inline-block
  @include mb(2)
  text-transform: uppercase
  letter-spacing: 4.5px
  font-weight: 400

  @media (prefers-color-scheme: light)
    font-weight: 500


.frame
  border: 4px solid #98496e
  @include pt(7)
  @include pb(7)

.content-text
  @include pl(5)
  @include pr(5)
</style>

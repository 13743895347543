<template>
  <b-modal v-model="trackingModalShow" @hide="hideTrackingModal" centered>
    <template #modal-header="{ close }">
      <h5>Cookies</h5>
    </template>

    <template #default="{ hide }">
      <p>
        Hier werden Cookies verwendet. Die Datenschutzbestimmung kann
        <b-link class="font-weight-bold" href="/rechtlich/datenschutz">
          hier
        </b-link>
        gelesen werden.
      </p>

      <b-button size="lg" variant="primary" @click="confirmTracking">
        Ok
      </b-button>
    </template>

    <template #modal-footer="{ ok, cancel, hide }">
      <b-link href="/rechtlich/datenschutz">Weitere Informationen</b-link>
      <b-button size="lg" variant="primary" @click="confirmTracking">
        Ok
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { bootstrap } from 'vue-gtag'
import Cookies from 'js-cookie'

export default {
  components: {},
  mixins: [],
  data() {
    return {
      trackingModalShow: false
    }
  },
  props: {},
  mounted() {},
  watch: {
    $route: {
      handler: function(route) {
        if (!Cookies.get('tracking_confirmed')) {
          this.trackingModalShow = true
        }

        if (route.path == '/rechtlich/datenschutz') {
          this.trackingModalShow = false
          this.hideTrackingModal()
        }
      }
    }
  },
  methods: {
    enableGtagTracking() {
      // eslint-disable-next-line no-unused-vars
      bootstrap().then(gtag => {
        // all done!
      })
    },
    confirmTracking() {
      Cookies.set('tracking_confirmed', true)
      this.trackingModalShow = false
      this.enableGtagTracking()
    },
    hideTrackingModal(bvModalEvt) {
      if (this.trackingModalShow) {
        bvModalEvt.preventDefault()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
::v-deep .modal-dialog
  align-items: end !important

  .modal-content
    background-color: $color-background-light
    color: map-get($grays, "700")

    @media (prefers-color-scheme: light)
      background-color: $color-background
      color: map-get($grays, "500")

    .modal-body
      display: flex
      align-items: center
      justify-content: center

      p
        margin: 0

    .modal-header, .modal-footer
      display: none

.status
  width: 100%

.commit
  display: flex
  justify-content: space-between

  span
    display: inline-block
</style>

import Vue from 'vue'
import Vuex from 'vuex'

import mailStore from './core/MailStore'

Vue.use(Vuex)
export default new Vuex.Store({
  plugins: [],
  modules: {
    mailStore: mailStore.mailStoreModule
  }
})
